/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Keramzyt pełni istotną rolę jako warstwa drenażowa w doniczkach roślinnych. Umieszczony na dnie doniczki, keramzyt zapobiega nadmiernemu zatrzymywaniu wody w podłożu, co może prowadzić do nadmiernego namaczania korzeni roślin. Dzięki swojej porowatej strukturze, keramzyt umożliwia swobodny przepływ wody, jednocześnie zapewniając odpowiednią wentylację korzeni."), "\n", React.createElement(_components.p, null, "Pojemności wiaderek:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "18 litrów"), "\n", React.createElement(_components.li, null, "10 litrów"), "\n", React.createElement(_components.li, null, "5,6 litra"), "\n", React.createElement(_components.li, null, "3,4 litra"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
